import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <Typewriter
      options={{
        strings: [
          "trainee as Software Engineer",
          "Software Engineer (not yet lol)",
          "Web Dev",
          "Python programmer",
          "NodeJS programmer",
          "React programmer",
          "no hobbies lol",
          "loves MongoDB",
          "and loves his girlfriend"
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 50,
      }}
    />
  );
}

export default Type;
