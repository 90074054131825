import React from "react";
import { Col, Row } from "react-bootstrap";
import { CgCPlusPlus } from "react-icons/cg";
import {
  DiJavascript1,
  DiReact,
  DiNodejs,
  DiPython,
  DiGit,
  DiJava,
  DiApple,
  DiBootstrap,
  DiLinux,
  DiMongodb,
  DiNginx,
  DiPerl,
} from "react-icons/di";

function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <span title="C++ and C"><CgCPlusPlus /></span>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <span title="Python"><DiPython /></span>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <span title="Java"><DiJava /></span>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <span title="JavaScript"><DiJavascript1 /></span>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <span title="React (JavaScript Framework)"><DiReact /></span>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <span title="NodeJs (JavaScript Framework)"><DiNodejs /></span>
      </Col>
      
      <Col xs={4} md={2} className="tech-icons">
        <span title="Git"><DiGit /></span>
      </Col>
      
      <Col xs={4} md={2} className="tech-icons">
        <span title="Apple/Swift"><DiApple /></span>
      </Col>
      
      <Col xs={4} md={2} className="tech-icons">
        <span title="Bootstrap HTML CSS Framwork"><DiBootstrap /></span>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <span title="Linux advanced"><DiLinux /></span>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <span title="MongoDB"><DiMongodb /></span>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <span title="Nginx"><DiNginx /></span>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <span title="Perl"><DiPerl /></span>
      </Col>

    </Row>
  );
}

export default Techstack;
